import React, { Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";

const CurrentDate = (props) => {
  const { lastUpdated } = props;

  let lastUpdateDateIso = moment(lastUpdated).format();
  let lastUpdateDateHuman = moment(lastUpdated).format("ddd MMM Do H:mm:ss");

  return (
    <Fragment>
      Last Update:{" "}
      <time dateTime={lastUpdateDateIso}>{lastUpdateDateHuman}</time>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    lastUpdated: state.arrivalsBoard.lastUpdated,
  };
};

export default connect(mapStateToProps)(CurrentDate);
