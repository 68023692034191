import React, { useEffect } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions/index";
import Home from "./components/home/Home";
import "./App.css";

const App = (props) => {
  const { onConnectWebsocket } = props;

  useEffect(() => {
    onConnectWebsocket();
  }, [onConnectWebsocket]);

  let routes = (
    <Switch>
      <Route path="/" exact component={Home} />
      <Redirect to="/" />
    </Switch>
  );
  return routes;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onConnectWebsocket: () => dispatch(actions.sockJsConnect()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(App));
